<template>
  <router-link
    v-if="to"
    v-slot="{ isActive }"
    v-tooltip="{
      content: tooltipLabel,
      placement: 'left',
      onShow: () => !sidebarExpanded
    }"
    class="flex cursor-pointer relative focus:outline-none max-w-full group/sidebar"
    :class="[sharedClassList, { 'py-3': accountButton }]"
    :to="to"
  >
    <slot name="icon"></slot>
    <div
      v-if="sidebarExpanded"
      data-cy="sidebar-button"
      class="place-self-center text-white/80 text-sm font-medium group-hover/sidebar:text-white transition-colors duration-200"
      :class="[
        { 'text-white': isActive || activeCondition },
        accountButton
          ? 'grow whitespace-nowrap overflow-hidden pl-4 pr-6 py-[1px]'
          : 'pl-2 pr-4'
      ]"
    >
      <slot name="label"></slot>
    </div>
    <slot />
  </router-link>

  <button
    v-else
    v-tooltip="{
      content: tooltipLabel,
      placement: 'left',
      onShow: () => !sidebarExpanded
    }"
    class="flex cursor-pointer relative focus:outline-none max-w-full group/sidebar"
    :class="[sharedClassList, { 'py-5': accountButton }]"
  >
    <slot name="icon"></slot>
    <div
      v-if="sidebarExpanded"
      class="place-self-center pr-4 text-sm font-medium group-hover/sidebar:text-white"
      :class="[
        accountButton ? 'pl-4' : 'pl-2',
        insetButton ? 'text-white' : 'text-white/80'
      ]"
    >
      <slot name="label"></slot>
    </div>
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";
const props = defineProps({
  to: {
    type: String,
    default: null
  },
  sidebarExpanded: {
    type: Boolean,
    default: false
  },
  accountButton: {
    type: Boolean,
    default: false
  },
  insetButton: {
    type: Boolean,
    default: false
  },
  insetColor: {
    type: String,
    default: ""
  },
  tooltipLabel: {
    type: String,
    default: ""
  },
  activeCondition: {
    type: Boolean,
    default: false
  }
});

const sharedClassList = computed(() => {
  const classes: Record<string, boolean | string> = {
    "justify-left": props.sidebarExpanded,
    "justify-center": !props.sidebarExpanded,
    "border-t border-white/10": props.accountButton,
    rounded: props.insetButton,
    "px-1 rounded": !props.insetButton && !props.accountButton,
    "hover:bg-white/10": !props.insetColor,
    [props.insetColor]: props.insetColor,
    "py-2": props.sidebarExpanded && props.insetButton,
    "pl-4": props.sidebarExpanded && props.accountButton,
    "router-link-active": props.activeCondition
  };
  return Object.keys(classes)
    .filter((key) => classes[key])
    .join(" ");
});
</script>

<style scoped>
.router-link-active {
  @apply bg-white/10;
}
</style>
